.privacy-scroll-box{
    max-height: 554px;
    overflow: hidden;
    overflow-y: auto;
    font-family: Montserrat-Medium;
    text-align: start;
    color: white;
    line-height: 22px;
    margin: 0;
    scrollbar-color: #707070 #000;
    scrollbar-width: thin;
}

.privacy-scroll-box::-webkit-scrollbar {
    width: 5px;
    background-color: #000;
    border-radius: 6px;
  }
  .privacy-scroll-box::-webkit-scrollbar-thumb{
    background-color: #707070;
    border-radius: 6px;
  }
  .privacy-scroll-box p {
    width: 98%;
}
.social-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
    font-size: 16px;
    width: 100%;
}

.flip-card {
    position: relative;
    background-color: transparent;
    max-width: 18em;
    width: 100%;
    height: 18em;
    perspective: 80em;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s; /* control the flip animation speed */ 
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card:active .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 1.5em;
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.flip-card-back {
    transform: rotateY(180deg);
}

.flip-card-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 1733px) {
    .social-container {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 1120px) {
    .flip-card {
        max-width: 16em;
        height: 16em;
    }

    .flip-card-content {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 1016px) {
    .flip-card {
        max-width: 14em;
        height: 14em;
    }

    .social-container {
        justify-content: center;
    }

    .flip-card-content {
        font-size: 1.3em;
    }
}

@media only screen and (max-width: 870px) {
    .flip-card {
        max-width: 13em;
        height: 13em;
    }
    .social-container {
        gap: 0.9em;
    }
}

@media only screen and (max-width: 802px) {
    .flip-card {
        max-width: 18em;
        height: 18em;
    }
}

@media only screen and (max-width: 740px) {
    .flip-card {
        max-width: 15em;
        height: 15em;
    }
}

@media only screen and (max-width: 644px) {
    .flip-card {
        max-width: 13em;
        height: 13em;
    }
}

@media only screen and (max-width: 510px) {
    .flip-card {
        max-width: 23em;
        height: 23em;
    }
}

@media only screen and (max-width: 450px) {
    .flip-card {
        max-width: 18em;
        height: 18em;
    }

    .flip-card-content {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 360px) {
    .flip-card {
        max-width: 15em;
        height: 15em;
    }

    .flip-card-content {
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 360px) {
    .flip-card {
        max-width: 14em;
        height: 14em;
    }

    .flip-card-content {
        font-size: 1.3em;
    }
}
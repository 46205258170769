.contact-page-sub-title{
    font-size: 1.7em;
    font-family: Montserrat-Medium;
    text-align: left;
    max-width: 660px;
    color: white;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: unset;
}

.contact-uoter-box{
    width: 100%;
    overflow: hidden;
    margin: auto;
    backdrop-filter: blur(6px) brightness(1.1);
    border-radius: 29px;
    max-width: 1100px
}

.form-holder{
    padding: 33px 77px 29px 77px;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
}

.input-holder{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.input-holder label {
    text-align: start;
    line-height: 24px;
    font-size: 1.4em;
    font-family: Montserrat-Medium;
    color: white;
    margin-bottom: 9px;
    font-weight: bold;
}
.input-holder input {
    padding: 0px 5px;
    height: 33px;
    resize: none;
    font-size: 1.4em;
    font-family: Montserrat-Medium;
    background: #292727;
    color: white;
    border-radius: 8px;
    border: 1px solid #707070;
}
.input-holder textarea {
    padding: 5px;
    height: 136px;
    resize: none;
    font-size: 1.4em;
    font-family: Montserrat-Medium;
    background: #292727;
    color: white;
    border-radius: 8px;
    border: 1px solid #707070;
}
.contact-submit-button{
    font-family: Montserrat-Medium;
    font-weight: bold;
    max-width: 382px;
    width: 100%;
    height: 48px;
    border: 1px solid #fff;
    border-radius: 12px;
    font-size: 24px;
    background: radial-gradient(#008E59, #000708);
    color: #fff;
    cursor: pointer;
    text-shadow: 2px 3px 4px #000000;
}
.contact-submit-button:disabled,
.contact-submit-button[disabled]{
  border: 1px solid #999999;
  background: #cccccc;
  color: #666666;
  pointer-events: none;
}
.is-hidden{
    display: none;
}
.modal-element-holder{
    border: 3px solid #ffd500;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 14px;
}
.modal-element-holder p{
    font-family: Montserrat-Medium;
    text-align: center;
    color: white;
    margin: 0;
    font-size: 35px;
}

@media (max-width: 740px) {
    .form-holder{
        padding: 25px 22px 30px 22px;
    }
}

@media (max-width: 531px) {
    .contact-submit-button {
        width: 85%;
        font-size: 20px;
    }
}

@media (max-width: 350px) {
    .form-holder{
        padding: 15px 10px 20px 10px;
    }
    .contact-page-sub-title {
        text-align: center;
    }
}
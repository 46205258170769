.homepage-container{
    color:#fff;
    font-size: 16px;
    padding: 115px 60px 60px 60px;
    background-size: cover;
    background-position: center;
}

.homepage-container p{
    margin: 0;
}

.homepage-container img{
    width: 100%;
}

.homepage-row{
    margin: 0 auto;
}

.home-column {
    flex-basis: 200px;
    margin: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right {
    flex: 50%;
}

.left {
    flex: 32%;
}

.home-column img div {
    width: 100%;
}

@media screen and (max-width: 885px){
    .column{
        flex: 100%;
    }
}

.home-description {
    font-size: 1.6em;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0.5em 0em;
}

.home-description {
    margin: 1em 0em;
}

.bold-text {
    font-weight: bold;
}

.play-now-btn-home {
    max-width: 411px;
    margin: 0 auto;
    margin-top: 0.7em;
    font-size: 1.9em;
    background: radial-gradient(rgb(0, 142, 89, 100%), rgb(0, 7, 8, 0%));
    border: solid 1px white;
    border-radius: 10px;
    font-weight: 700;
    padding: 1em 1.4em;
    cursor: pointer;
}

.store-icons {
    max-width: 27em;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.store-icons img {
    width: 50%;
    cursor: pointer;
}

.mobile-banner {
    margin-bottom: 15px;
}

@media only screen and (max-width: 1670px) {
    .homepage-container {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1480px) {
    .homepage-container {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1292px) {
    .homepage-container {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1060px) {
    .homepage-container {
        padding: 30px 50px;
    }

    .play-now-btn-home { 
        padding: 0.8em 1.2em;
        font-size: 1.7em;
    }

    .store-icons {
        max-width: 25em;
    }
}

@media only screen and (max-width: 885px) {
    .store-icons {
        max-width: 45em;
        margin: unset;
        margin-top: -70px;
    }
}

@media only screen and (max-width: 660px) {
    .homepage-container {
        font-size: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .homepage-container {
        font-size: 8px;
    }
}

@media only screen and (max-width: 512px) {
    .homepage-container {
        padding: 20px;
    }
}

@media only screen and (max-width: 320px) {
    .homepage-container {
        padding: 15px;
    }
}

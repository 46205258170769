.web3-container {
    width: 60%;
    text-align: left;
    font-size: 1.7em;
    backdrop-filter: blur(6px) brightness(0.6);
    border-radius: 1em;
    padding: 2em 1.4em;
    position: relative;
}

.first-line {
   position: absolute;
   width: 690px;
   height: 6px;
   background: white; 
}

.first-line {
    z-index: 1;
    right: 34%;
    top: 33px;
}

.second-line {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
}

.second-line {
    bottom: 33px;
    left: 34%;
}

.white-line {
    width: 650px;
    height: 6px;
    background: white; 
}

.yellow-line {
    width: 40px;
    height: 6px;
    background: #F1AA03; 
}


@media only screen and (max-width: 1025px) {
    .web3-container {
        padding: 15px 26px;
        width: 80%;
    }
    .second-line {
        bottom: 15px;
    }
    .first-line {
        top: 15px;
    }
}

@media only screen and (max-width: 760px) {
    .web3-container {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 630px) {
    .web3-container {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .padding-container {
        padding: 0px 46px;
    }
    .first-line {
        right: 100px;
    }
    .second-line {
        left: 100px;
    }
}

@media only screen and (max-width: 450px) {
    .web3-container {
        width: 100%;
        font-size: 1.4em;
        padding: 15px 15px;
    }
    .first-line, .white-line, .yellow-line {
        height: 5px;
    }
    .second-line {
        bottom: 13px;
    }
    .first-line {
        top: 13px;
    }
}

@media only screen and (max-width: 360px) {
    .web3-container {
        width: 95%;
    }
    .padding-container {
        padding: 0px 16px;
    }
}

.box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    max-width: 82%;
    margin: auto;
    padding: 0em 2em;
}
.poker-rules-box {
    position: relative;
    padding: 55px 86px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    backdrop-filter: blur(30px) brightness(1.7);
    width: 82%;
}
.poker-rules-title {
    font-size: 45px;
    text-align: left;
    margin: 0;
    text-shadow: 0px 3px 6px #000000;
}
.poker-rules-text {
    font-size: 25px;
    text-align: left;
    margin: 0;
}
.poker-rule-line {
    width: 60%;
    margin: 0 auto;
}
.card-type {
    width: 65%;
    margin: 0 auto;
}

/* poker-hand */
.poker-hand-title, .poker-hand-description, .poker-hand-footerText {
    text-align: left;
    margin: 0px;
    font-size: 25px;
}
.poker-hand-title {
    font-weight: bold;
    margin-bottom: 5px;
    text-shadow: 0px 3px 6px #000000;
    font-family: 'Montserrat-ExtraBold';
}
.poker-hand-footerText {
    margin-top: 30px;
    font-family: 'Montserrat-ExtraBold';
}
.poker-hand-footerText span{
    margin-top: 30px;
    font-family: 'Montserrat-Medium';
}
.cards {
    max-width: 100%;
    margin-top: 35px;
}
/* starting-hands-texas */
.starting-hand-texas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}
.starting-hands {
    display: flex;
    gap: 10%;
    align-items: center;
    justify-content: space-between;
}
.starting-hands .poker-hand-description {
    min-width: 20%;
    max-width: 750px;
}
.image-container {
    max-width: 400px;
}
.image-container img{
    width: 100%;
}

/* starting-hands-omaha */
.starting-hands-omaha {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}
.starting-hand-omaha {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.omaha-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.poker-hand-rank {
    font-size: 75px;
    margin-top: 20px;
}
.omaha-cards {
    max-width: 100%;
}

@media only screen and (max-width: 900px) {
    .poker-rules-box {
        padding: 30px 30px;
    }
    .poker-rules-title {
        font-size: 40px;
        text-align: center;
    }
    .poker-rules-text {
        font-size: 18px;
        text-align: center;
    }
    .poker-hand-title {
        font-size: 20px;
        text-shadow: unset;
    }
    .poker-hand-description {
        font-size: 18px;
    }
    .poker-hand-footerText {
        font-size: 18px;
    }
    .poker-hand-rank {
        font-size: 40px;
    }
    .poker-rule-line {
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .poker-rules-title {
        font-size: 20px;
        text-align: center;
    }
    .card-type {
        width: 100%;
        margin: 0 auto;
    }
    .poker-rules-text {
        font-size: 14px;
        text-align: center;
    }
    .poker-hand-title {
        font-size: 16px;
    }
    .poker-hand-description {
        font-size: 14px;
    }
    .poker-hand-footerText {
        font-size: 14px;
    }
    .starting-hands {
        flex-wrap: wrap;
        justify-content: center;
    }
    .starting-hands img {
        margin-top: 20px;
    }
    .starting-hands img {
        max-width: 80%
    }
    .poker-hand-rank {
        font-size: 20px;
        margin-top: 0px;
    }
    .poker-rule-line {
        width: 90%;
    }
}

@media only screen and (max-width: 950px) { 
    .box-container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) { 
    .box-container {
        max-width: 95%;
    }
}
.card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    flex-wrap: wrap;
}

.card-container:nth-of-type(even) {
    flex-direction: row-reverse;
}

.card-image {
    flex: 0.8;
    height: 100%;
}

.card-image img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}

.card-content {
    flex: 1;
    padding: 1.6em 1em 1em 1em; 
    position: relative;
    text-align: left;
    backdrop-filter: blur(6px) brightness(1.5);
    font-size: 1em;
}

.card-header img {
    width: 2.2em;
    height: 100%;
}

.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2em;
}

.card-title {
    font-size: 2.5em;
    margin: unset;
}

.card-description {
    font-size: 1.5em;
    margin: unset;
    padding-top: 0.6em;
}

.info-icon {
    cursor: pointer;
}

.device-icons {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    display: flex;
    gap: 1em;
}

@media screen and (max-width: 1479px) {
    .card-container {
      font-size: 14px;
    }
}

@media screen and (max-width: 1347px) {
    .card-container {
      font-size: 12px;
    }
}

@media screen and (max-width: 1251px) {
    .card-container {
        font-size: 11px;
    }
}

@media screen and (max-width: 1142px) {
    .device-icons .smartphone {
        width: 1.3em;
        height: 100%;
    }

    .device-icons .desktop {
        width: 3em;
        height: 100%;
    }

    .card-container {
        font-size: 10px;
    }
}

@media screen and (max-width: 999px) {
    .card-content {
        flex: 1;
    }
    .card-image {
        flex: 1;
    }
}

@media screen and (max-width: 950px) {
    .card-container, .card-container:nth-of-type(even) {
        flex-direction: column;
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {
    .card-container, .card-container:nth-of-type(even) {
      font-size: 10px;
    }
}

@media screen and (max-width: 360px) {
    .card-container, .card-container:nth-of-type(even) {
      font-size: 8px;
    }
}
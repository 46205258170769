.title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text {
    font-size: 80px;
    font-weight: bold;
}

.diamond-logo img {
    width: 50px;
}

@media screen and (max-width: 1500px) {
    .text {
      font-size: 70px;
    }
}

@media screen and (max-width: 1367px) {
    .text {
      font-size: 60px;
    }
}

@media screen and (max-width: 1200px) {
    .text {
      font-size: 50px;
    }
}

@media screen and (max-width: 1080px) {
    .text {
        font-size: 40px;
    }
}

@media screen and (max-width: 950px) {
    .line {
      margin: auto 0;
    }
}

@media screen and (max-width: 570px) {
    .text {
      font-size: 35px;
    }
    .line {
        height: 10px;
    }
}


@media screen and (max-width: 500px) {
    .text {
      font-size: 30px;
    }
}

@media screen and (max-width: 480px) {
    .line {
        width: 50px;
    }
    .text {
      font-size: 27px;
    }
}

@media screen and (max-width: 370px) {
    .text {
        text-align: center;
    }
    .line {
        width: 25px;
    }
}
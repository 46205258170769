.four-column{
    flex: 21%;
}
.column-content{
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 1em;
    max-width: 100%;
}

.card{
    margin: auto;
}

.column-title{
    font-family: "Montserrat-Medium";
    font-size: 2.5em;
    color:#fff;
    font-weight: 600;
    text-shadow: 2px 3px 4px #000000cf;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline; 
    text-underline-offset: 10px;
    padding-bottom: 10px;
}
.column-description{
    font-size: 1.1em;
    font-family: "Montserrat-Medium";
    max-width: 390px;
    margin: auto;
    color:#fff;
    padding: 10px 20px;
    text-shadow: 2px 3px 4px #000000cf;
    text-align: left;
}

@media screen and (max-width: 1532px) {
    .column-content img {
        max-width: 80%;
    }
    .column-content {
        font-size: 1em;
    }
}

@media screen and (max-width: 1100px) {
    .four-column{
        flex: 40%;
    }
}

@media screen and (max-width: 700px) {
    .four-column{
        flex: 100%;
    }
    .column-content {
        display: flex;
        flex-direction: row;
        gap: 0px;
        min-width: 230px;
    }
    .column-description {
        padding-right: 0px;
    }
}

@media screen and (max-width: 470px) {
    .column-content img {
        width: 40%;
    }
}
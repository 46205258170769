.column{
    flex: 31.9% 1;
    flex-basis: 200px;
    margin: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 885px){
    .column{
        flex: 100%;
    }
}

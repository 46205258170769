.carousel-container{
    max-width: 1300px;
    border-radius: 35px;
    border: 3px solid #FFD500;
    padding: 20px 30px 10px 30px;
    margin: 0 20px;
    background: linear-gradient(#8E0A37, #2E0031);
}
.carousel-title{
    font-size: 42px;
    padding-bottom: 30px;
    text-align: center;
    color: #fff;
    filter: drop-shadow(0px 3px 6px black)
}
.carousel img{
    width: unset!important;
    max-width: 100%;
    margin: 0 auto;
}
.carousel-slider{
    padding-bottom: 50px;
}
.row-car{
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
    flex-wrap: wrap;
}
.col-car{
    flex: 50%;
}
.carousel-text{
    height: 100%;
    display: inline-block;
    font-weight: bold;
}
.wrap {
    display: table;
    height:  100%;
    width:   95%;
    padding: 10px;
    padding-bottom: 65px;
    float: right;
}
  .wrap p {
    display:        table-cell;
    vertical-align: middle;
  }
.carousel-text p{
    color: #fff;
    font-size: 22px;
}
.closeButton{
    position: absolute;
    right: 20px;
    top: 8px;
    background-color: transparent;
    border: 0;
}
.closeButton:hover{
    cursor: pointer;
}
.ReactModal__Overlay {
    z-index: 12312312312312423423;
    background-color: rgba(20,67,112,0.75) !important;
}
.ReactModal__Content{
    background-color: transparent!important;
    border: none!important;
    max-width: 100%;
}
.carousel .control-dots .dot{
    width: 15px;
    height: 15px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0);
}
.carousel .control-next.control-arrow:before {
    border-left: 28px solid #fff; 
}
.carousel .control-prev.control-arrow:before {
    border-right: 28px solid #fff; 
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
    padding: 30px;
    bottom: 50px;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}
.mobile-carousel-img{
    display: none!important;
    max-height: 294px;
}
.desktop-carousel-img{
    display: block;
}
@media only screen and (max-width: 1320px) 
{
    .carousel-text p{
        font-size: 18px;
    }
    .row-car{
        max-width: 900px;
    }
    .carousel-title{
        font-size: 35px;
    }
}
@media only screen and (max-width: 1200px) 
{
    .carousel-text p{
        font-size: 16px;
    }
    .row-car{
        max-width: 700px;
    }
    .carousel-title{
        font-size: 30px;
    }
}
@media only screen and (max-width: 1025px) 
{
    .carousel-container {
        max-width: 340px;
    }
    .carousel-slider{
        padding-bottom: 10px;
    }
    .carousel-text p{
        padding-top: 40px;
        font-size: 15px;
        padding-bottom: 10px;
    }
    .carousel .control-dots .dot{
        margin: 0 5px;
    }
    .col-car{
        flex: 100%;
    }
    .carousel-title{
        font-size: 25px;
        padding: 20px 10px 0 10px;
    }
    .mobile-carousel-img{
        display: block!important;
    }
    .desktop-carousel-img{
        display: none!important;
    }
    .wrap {
        padding-bottom: 0;
    }
    .carousel .control-dots .dot{
        width: 8px;
        height: 8px;
    }
    .carousel .control-dots {
        top: 300px !important;
    }
    .carousel .control-arrow, .carousel.carousel-slider .control-arrow{
        padding: 0;
        bottom: 150px
    }
    .carousel .control-next.control-arrow:before {
        border-left: 15px solid #fff; 
    }
    .carousel .control-prev.control-arrow:before {
        border-right: 15px solid #fff; 
    }
    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
    .closeButton{
        right: 25px;
        top: 10px;
    }
    .closeButton img{
        max-width: 40px;
    }
}

@media only screen and (max-width: 450px) 
{
    .carousel-title {
        font-size: 20px;
    }  
}
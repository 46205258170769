.row-plus{
    display: flex;
    max-width: 1725px;
    margin: 0 auto;
    flex-wrap: wrap;
}
.play-now-btn-sopoPlus{
    color:#fff;
    font-size: 40px!important;
    padding: 13px 35px!important;
    margin-top: 20px;
}
.play-now-btn-sopoPlus:hover{
    cursor: pointer;
}
.sopo-plus-c{
    background-image: url("../../assets/images/sopoPlus/charcoal-suited-speed-cloth.png");
    background-size: cover;
    padding-top: 50px;
}
.social-plus-d{
    text-align: left;
}
.sopo-plus-c .column-description{
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    max-width: 610px;
    font-size: 27px;
    font-weight: 500;
}
.sopo-plus-c .column:first-child{
    align-items: flex-start;
}
.sopo-plus-c .column:nth-child(2){
    align-items: flex-end;
}
.big-img-mobile{
    display: none;
}
@media screen and (max-width: 1024px) {
    .sopo-plus-c{
        padding-top: 20px;
    }
    .sopo-plus-c img{
        max-width: 100%!important;
    }
    .social-plus-d{
        text-align: center;
    }
    .social-plus-d img{
        max-width: 250px!important;
    }
    .sopo-plus-c .column-description{
        font-size: 14px;
        text-align: center;
    }
    .big-img-mobile{
        display: block;
    }
    .sopo-plus-c .carousel-text{
        display: none;
    }

    /* columns */
    .tournament-staking .column:first-child{
        align-items: center;
    }
    .tournament-staking .column:nth-child(2),.tournament-staking .column:nth-child(3){
        flex: 44%!important;
    }
    .tournament-staking .column:first-child img,.tournament-staking .column:nth-child(2) img,.tournament-staking .column:nth-child(3) img{
        width: 135px!important;
        max-width: 100%!important;
    }
    .tournament-staking .column:first-child,.tournament-staking .column:nth-child(2){
        align-items: center;
    }

    .play-now-btn-sopoPlus{
        font-size: 21px!important;
        padding: 11px 35px!important;
    }
    .s-p-bottom-p p{
        font-size: 12px!important;
        padding: 0 30px;
    }
    .sk{
        width: 70px!important;
    }
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat-Medium', 'Montserrat-ExtraBold', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #001736;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* importing fonts */

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"), url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"), url(./assets/fonts/Montserrat-ExtraBold.ttf) format("truetype");
  font-display: swap;
}
.d-flex{
  display: flex;
}
.p-relative{
  position: relative;
}
.j-center{
  justify-content: center;
}
.j-end{
  justify-content: flex-end!important;
}
.j-start{
  justify-content: flex-start!important;
}
.py-10{
  padding-top:10px;
  padding-bottom:10px;
}
.text-left{
  text-align: left;
}
.wide-container{
  padding: 0 20px;
  margin: 0 auto;
}
.wide-container-inner{
  padding: 0 20px;
}
.hoverPointer:hover{
  cursor: pointer;
}
.gradient-red-bg{
  background: linear-gradient(#8E0A37, #2E0031);
}
.gradient-box-title{
  color: '#FFFFFF';
  font-size: 64px;
  font-family: 'Montserrat-Medium';
  font-weight: bolder;
  text-align: center;
}
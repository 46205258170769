.container{
    max-width: 1050px;
}
.row{
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

@media screen and (max-width: 1670px) {
    .container {
        max-width: 950px;
    }
 }

@media screen and (max-width: 1532px) {
    .container {
        max-width: 850px;
    }
    .row {
        gap: 1em;
    }
}

@media screen and (max-width: 1240px) {
    .container {
        max-width: 750px;
    }
}